import { Button as MarmaladeButton } from '@eventbrite/marmalade';
import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import { isExternalUrl } from '../../../utils/context/utils';
import { VARIANTS } from '../../../utils/types';
import './ctaBlock.scss';
import {
    CTA_BLOCK_BUTTON_VARIANT,
    CTA_BLOCK_LARGE_SIZE,
    CTA_BLOCK_LINK_VARIANT,
    CTA_BLOCK_REGULAR_SIZE,
} from './utils';

export interface LinkBlockInterface {
    callToAction: string;
    direction?: string;
    themeVariant?: VARIANTS;
    card?: boolean;
}
export interface ButtonBlockInterface extends LinkBlockInterface {
    size?: typeof CTA_BLOCK_LARGE_SIZE | typeof CTA_BLOCK_REGULAR_SIZE;
    secondary?: boolean;
}
export interface CTABlockInterface extends ButtonBlockInterface {
    url?: string;
    variant: typeof CTA_BLOCK_BUTTON_VARIANT | typeof CTA_BLOCK_LINK_VARIANT;
}

const ButtonBlock = ({
    size,
    callToAction,
    secondary,
    themeVariant,
}: ButtonBlockInterface) => {
    const theme = useTheme();
    const [buttonClass] = theme.generateStyles(
        secondary ? 'button-secondary' : 'button-primary',
        {
            variant: themeVariant,
        },
    );
    return (
        <MarmaladeButton
            className={`${buttonClass} cta-block__button__${
                size || CTA_BLOCK_REGULAR_SIZE
            }`}
            variant="primary"
            size={size == CTA_BLOCK_LARGE_SIZE ? 'block' : undefined}
        >
            {callToAction}
        </MarmaladeButton>
    );
};

const LinkBlock = ({
    callToAction,
    direction,
    themeVariant,
    card,
}: LinkBlockInterface) => {
    const theme = useTheme();
    const [ctaLinkClassName] = theme.generateStyles(
        card === true ? 'card-ctaLink' : 'ctaLink',
        {
            config: { direction },
            variant: themeVariant,
        },
    );

    return (
        <span className={`cta-block__link ${ctaLinkClassName}`}>
            {callToAction}
        </span>
    );
};

const CTABlock = ({
    callToAction,
    url,
    size,
    variant,
    secondary,
    themeVariant,
    card,
}: CTABlockInterface) => {
    const isExternal = url ? isExternalUrl(url) : false;

    const blockVariant =
        variant === 'button' ? (
            <ButtonBlock
                size={size}
                callToAction={callToAction}
                secondary={secondary}
                themeVariant={themeVariant}
            />
        ) : (
            <LinkBlock
                callToAction={callToAction}
                direction={url?.startsWith('#') ? 'down' : 'right'}
                themeVariant={themeVariant}
                card={card}
            />
        );
    return (
        <a
            className="cta-block"
            href={url}
            {...(isExternal && {
                target: '_blank',
                rel: 'nofollow noopener noreferrer',
            })}
        >
            {blockVariant}
        </a>
    );
};

export default CTABlock;
