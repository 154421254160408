import { Icon } from '@eventbrite/eds-icon';
import classNames from 'classnames';
import chunk from 'lodash/chunk';
import React, { useEffect, useState } from 'react';
import { updateExternalLinks } from '../../../utils/context/utils';
import CtaButton, {
    CtaButtonInterface,
} from '../../sharedComponents/CtaButton';
import LazyloadImage from '../../sharedComponents/LazyloadImage';
import { sanitizeHTML } from '../utils';
import { getIconType, relativeZindexStyle } from './../utils';
import './productHighlight.scss';

const headlineClasses = classNames(
    'eds-text-color--grey-900',
    'eds-text-hm',
    'eds-text--center',
    'mktg-text-weight--heavy',
    'eds-l-mar-hor-2',
);
const subheaderClasses = classNames(
    'eds-text-color--grey-700',
    'eds-text-bl',
    'eds-text-weight--regular',
    'eds-l-mar-top-4',
    'eds-l-mar-hor-2',
    'eds-text--center',
);
const gridClasses = classNames(
    'eds-g-cell-12-12',
    'eds-g-offset-0-12',
    'eds-g-cell-sw-12-12',
    'eds-g-cell-mn-5-12',
    'eds-g-cell-md-6-12',
    'eds-g-offset-md-0-12',
);
const imageClasses = gridClasses + ' eds-l-pad-bot-8';

interface HeaderProps {
    headline: string;
    subheader: string;
}
const Header = ({ headline, subheader }: HeaderProps) => (
    <div className="eds-g-cell eds-g-cell-12-12 eds-g-offset-0-12 eds-g-cell-md-8-12 eds-g-offset-md-2-12">
        <div className="eds-l-mar-top-6 eds-l-pad-bot-14">
            <h2 className={headlineClasses}> {headline} </h2>
            <p className={subheaderClasses}>{subheader}</p>
        </div>
    </div>
);

interface Icon {
    text: string;
    icon: string;
}
interface IconListProps {
    icons: Icon[];
}
const IconRow = ({ icons }: IconListProps) => (
    <div className="eds-g-group">
        <ul className="eds-text--left">
            {icons.map((icon, index) => (
                <li
                    className="eds-g-cell eds-g-cell-1-2 eds-l-pad-vert-4 eds-l-pad-hor-4"
                    key={`${icon.icon}-${index}-${icon.text}`}
                >
                    <Icon
                        type={getIconType(icon.icon)}
                        size="small"
                        color="ui-orange"
                    />
                    <div
                        className="eds-text-bm eds-text-color--grey-700 eds-l-pad-top-2"
                        //eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: icon.text,
                        }}
                    />
                </li>
            ))}
        </ul>
    </div>
);

const IconTable = ({ icons }: IconListProps) => (
    <div className="eds-l-pad-top-6">
        {chunk(icons, 2).map((iconRow, index) => (
            <IconRow key={index} icons={iconRow} />
        ))}
    </div>
);

export interface ProductHighlightProps {
    headline: string;
    subheader: string;
    description: string;
    image: string;
    alt?: string;
    iconGroup: Icon[];
    productImageAlignment: string;
    cta: CtaButtonInterface[];
    moduleClass: string;
}
const ProductHighlightModule = ({
    headline,
    subheader,
    description,
    image,
    alt,
    iconGroup,
    productImageAlignment,
    cta,
    moduleClass,
}: ProductHighlightProps) => {
    const headerProps = { headline, subheader };
    const [updatedHtmlContent, setUpdatedHtmlContent] = useState(description);

    useEffect(() => {
        const updatedHTMLContent = updateExternalLinks(description);
        setUpdatedHtmlContent(updatedHTMLContent);
    }, [description]);

    return (
        <section
            className={`eds-bg-color--white product-highlight-module ${moduleClass}`}
        >
            <div className="mktg-top-p-100">
                <div className="marketing-container__max-width eds-l-pad-hor-6">
                    <div className="eds-g-grid ">
                        <div className="eds-g-group eds-l-mar-top-6">
                            {(headline || subheader) && (
                                <Header {...headerProps} />
                            )}
                            <div
                                className={`mktg-device-${productImageAlignment}-align`}
                            >
                                <div className={imageClasses}>
                                    <div className="l-align-center circle-mar-top">
                                        <LazyloadImage
                                            image={image}
                                            alt={alt || headline}
                                            classes="eds-max-img marketing-image--full-width"
                                        />
                                    </div>
                                </div>
                                <div
                                    className={gridClasses}
                                    style={relativeZindexStyle}
                                >
                                    <div className="l-align-left eds-l-pad-hor-4">
                                        <div
                                            className="rich-text eds-text-bl"
                                            //eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHTML(
                                                    updatedHtmlContent,
                                                ),
                                            }}
                                        />
                                        <IconTable icons={iconGroup} />
                                        <div className="eds-text--center eds-l-mar-bot-6">
                                            {cta[0] && (
                                                <CtaButton {...cta[0]} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ProductHighlightModule;
